import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { PopupmodelService } from 'src/app/services/popupmodel.service';

@Component({
  selector: 'app-userchange',
  templateUrl: './userchange.page.html',
  styleUrls: ['./userchange.page.scss'],
})
export class UserchangePage implements OnInit {
  adminForm: FormGroup;
  allUsers: any = [];
  records: any = [];
  availCards: any = [];

  constructor(
    private http: HttpService,
    private _formBuilder: FormBuilder,
    private navParams: NavParams,
    private popup: PopupmodelService,
    private modalCtrl: ModalController
  ) { 

    this.getRecords();
    this.getCards();

    this.adminForm = this._formBuilder.group({
      Id: '',
      Wallet_Id: ['', Validators.required],
      user_id: ['', Validators.required]
    });

    if(this.navParams.get('record')){
      let record = JSON.parse(this.navParams.get('record'));
      this.adminForm.patchValue({
        Id: record.Id,
        Wallet_Id: record.Wallet_Id,
        user_id: record.user_id
      });
    }

    if(this.navParams.get('records')){
      this.records = this.navParams.get('records');
      console.log(this.records);
    }
  }
  closeModal(){
    this.modalCtrl.dismiss();
  }
  getCards(){
    let formdata = new FormData();
    let data = {"query":"SELECT * FROM sft_bill_wallets WHERE User_Id='0' AND isActive='1'"};
    formdata.append("table","sft_bill_wallets");
    formdata.append("data",JSON.stringify(data)); 
    formdata.append("action","select");
    this.http.postDataByForm("common/common_api",formdata)
        .then((data: any) => {
          // console.log(data);
          if(data.status == 1){
              this.availCards  = data.data;
          }
        })
        .catch((err) => {
          let title = err.error.error.name;
          let message = err.error.error.message;
          if(err.error.error.name=='UnauthorizedError'){
            title = "Unauthorised Access";
            message = "Authorisation missing, please, login again";
          }
          this.popup.presentToast(message);
        })
        .finally(() => {});
  }
  getRecords(){
    let formdata = new FormData();
    let condition = " WHERE isActive='1' AND userrole='User'";
    if(localStorage.getItem("userRole")!='Admin'){
      condition += " AND Parent_Id='"+localStorage.getItem("userId")+"'";
    }
    let data = {"query":"SELECT * FROM sft_bill_user "+condition};
    formdata.append("table","sft_bill_user");
    formdata.append("data",JSON.stringify(data)); 
    formdata.append("action","select");
    this.http.postDataByForm("common/common_api",formdata)
        .then((data: any) => {
          // console.log(data);
          if(data.status == 1){
              this.allUsers  = data.data;
          }
        })
        .catch((err) => {
          let title = err.error.error.name;
          let message = err.error.error.message;
          if(err.error.error.name=='UnauthorizedError'){
            title = "Unauthorised Access";
            message = "Authorisation missing, please, login again";
          }
          this.popup.presentToast(message);
        })
        .finally(() => {});
  }
  ngOnInit() {
  }
  saveRecord(){
    let adminData = this.adminForm.value;
    this.allUsers.forEach(element => {
        if(element.Id == adminData.user_id){
          adminData.tl_id = element.Parent_Id;
        }
    });
    if(this.records.length>0){
      this.popup.presentLoading();
      this.records.forEach(element => {
          let data = {"Id": element.Id, "Bill_User_Id": adminData.user_id, "Bill_TL_Id": adminData.tl_id, "Wallet_Id": adminData.Wallet_Id};
          this.saveMoreData(data);
          let wData = {"Id": adminData.Wallet_Id,"User_Id": adminData.user_id};
          this.saveMoreData(wData,"sft_bill_wallets");
      });
      this.popup.dismisLoading();
      this.popup.presentAlert("User changed successfully", "Action User changed done successfully.");
      this.adminForm.reset(); 
      this.closeModal();
    }
    else {
      this.saveData(adminData);
    }
  }
  saveMoreData(data: any, tableName: any = 'sft_recharge'){
    let formdata = new FormData();
    formdata.append('data',JSON.stringify(data));
    formdata.append('action',"update");
    formdata.append('table',tableName);
    this.http.postDataByForm('common/common_api',formdata)
            .then((data: any) => {
            });
  }
  saveData(data: any){
    this.popup.presentLoading();
    let formdata = new FormData();
    formdata.append('data',JSON.stringify(data));
    formdata.append('action',"update");
    formdata.append('table','sft_recharge');
    this.http.postDataByForm('common/common_api',formdata)
            .then((data: any) => {
              this.popup.dismisLoading();
              this.popup.presentAlert("User changed successfully", "Action User changed done successfully.");
              if(data.status==1){
                this.adminForm.reset(); 
                this.closeModal();
              }
            });
  }
}
