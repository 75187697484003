import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { PopupmodelService } from 'src/app/services/popupmodel.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.page.html',
  styleUrls: ['./new-user.page.scss'],
})
export class NewUserPage implements OnInit {
  adminForm: FormGroup;
  actionName: any = "save";
  inProgress: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    private navParams: NavParams,
    private popUp: PopupmodelService,
    private http: HttpService
  ) { 

    if(localStorage.getItem("userRole")=="Admin"){
      this.isAdmin = true;
    }

    this.adminForm = this._formBuilder.group({
      Id: '',
      Name: ['', Validators.required],
      Contact_No: ['', Validators.required],
      Password: ['', Validators.required],
      userrole: ['User', Validators.required],
      isActive: ['1']
    });

    if(this.navParams.get('record')){
      this.actionName = "update";
      let record = JSON.parse(this.navParams.get('record'));
      this.adminForm.patchValue({
        Id: record.Id,
        Name: record.Name,
        Contact_No: record.Contact_No,
        Password: record.Password,
        userrole: record.userrole,
        isActive: record.isActive
      });
    }
  }
  closeModal(){
    this.modalCtrl.dismiss();
  }
  ngOnInit() {
  }
  saveRecord(){
    this.inProgress = true;
    this.popUp.presentLoading();
    let data = this.adminForm.value;
    if(data.Id == ""){
      if(localStorage.getItem("userRole")=="Admin"){
        data.userrole = "Team Leader";
      }
      else if(localStorage.getItem("userRole")=="Team Leader"){
        data.userrole = "User";
      }
      data.Parent_Id = localStorage.getItem("userId");
    }
    let formdata = new FormData();
    formdata.append('data',JSON.stringify(data));
    formdata.append('action',this.actionName);
    formdata.append('table','sft_bill_user');
    this.http.postDataByForm('common/common_api',formdata)
            .then((data: any) => {
              this.popUp.dismisLoading();
              this.inProgress = false;
              this.popUp.presentAlert(this.actionName+" successfully", "Action "+this.actionName+" done successfully.");
              if(data.status==1){
                this.adminForm.reset(); 
                this.closeModal();
              }
            });
  }
}
