import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { PopupmodelService } from 'src/app/services/popupmodel.service';

@Component({
  selector: 'app-add-card-data',
  templateUrl: './add-card-data.page.html',
  styleUrls: ['./add-card-data.page.scss'],
})
export class AddCardDataPage implements OnInit {

  adminForm: FormGroup;
  actionName: any = "save";
  inProgress: boolean = false;
  Users: any = [];

  constructor(
    private modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    private navParams: NavParams,
    private popUp: PopupmodelService,
    private http: HttpService
  ) { 
    this.adminForm = this._formBuilder.group({
      Id: '',
      Card_Type: ['', Validators.required],
      Card_No: ['', Validators.required],
      Expiry_date: ['', Validators.required],
      CVV: ['', Validators.required],
      Amount: ['', Validators.required],
      Contact_no: ['', Validators.required],
      Pin: [''],
      isActive: ['1']
    });

    if(this.navParams.get('record')){
      this.actionName = "update";
      let record = JSON.parse(this.navParams.get('record'));
      console.log(record);
      this.adminForm.patchValue({
        Id: record.Id,
        Card_Type: record.Card_Type,
        Card_No: record.Card_No,
        Expiry_date: record.Expiry_date,
        CVV: record.CVV,
        Amount: record.Amount,
        Contact_no: record.Contact_no,
        Pin: record.Pin,
        isActive: record.isActive
      });
    }
  }
  closeModal(){
    this.modalCtrl.dismiss();
  }
  ngOnInit() {
  }
  saveRecord(){
    this.inProgress = true;
    this.popUp.presentLoading();
    let data = this.adminForm.value;
    let formdata = new FormData();
    formdata.append('data',JSON.stringify(data));
    formdata.append('action',this.actionName);
    formdata.append('table','sft_bill_wallets');
    this.http.postDataByForm('common/common_api',formdata)
            .then((data: any) => {
              this.popUp.dismisLoading();
              this.inProgress = false;
              this.popUp.presentAlert(this.actionName+" successfully", "Action "+this.actionName+" done successfully.");
              if(data.status==1){
                this.adminForm.reset(); 
                this.closeModal();
              }
            });
  }
}
