import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { PopupmodelService } from 'src/app/services/popupmodel.service';

@Component({
  selector: 'app-wallet-topup',
  templateUrl: './wallet-topup.page.html',
  styleUrls: ['./wallet-topup.page.scss'],
})
export class WalletTopupPage implements OnInit {

  adminForm: FormGroup;
  actionName: any = "Save";
  inProgress: boolean = false;
  isAdmin: boolean = false;
  allUsers: any = [];
  constructor(
    private modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    private navParams: NavParams,
    private popUp: PopupmodelService,
    private http: HttpService
  ) {
    if(localStorage.getItem("userRole")=='Admin'){
        this.isAdmin = true;
    }
    this.adminForm = this._formBuilder.group({
      Id: '',
      User_Id: '',
      To_User_Id: ['', Validators.required],
      Transaction_type: ['Credit', Validators.required],
      Amount: ['', Validators.required],
      Remark: ''
    });

    if(this.navParams.get('records')){
      this.actionName = "Update";
      let record = JSON.parse(this.navParams.get('records'));
      this.adminForm.patchValue({
        Id: record.Id,
        User_Id: record.User_Id,
        To_User_Id: record.To_User_Id,
        Transaction_type: record.Transaction_type,
        Amount: record.Amount,
        Remark: record.Remark
      });
    }
  }
  closeModal(){
    this.modalCtrl.dismiss();
  }
  ngOnInit() {
    this.getRecords();
  }
  getRecords(){
    let formdata = new FormData();
    formdata.append("table","users WHERE Parent_Id='"+localStorage.getItem("userId")+"'");
    formdata.append("action","select");
    this.http.postDataByForm("common-api.php",formdata)
        .then((data: any) => {
          console.log(data);
          if(data.status == 1){
              this.allUsers  = data.data;
          }
        })
        .catch((err) => {
          let title = err.error.error.name;
          let message = err.error.error.message;
          if(err.error.error.name=='UnauthorizedError'){
            title = "Unauthorised Access";
            message = "Authorisation missing, please, login again";
          }
          this.popUp.presentToast(message);
        })
        .finally(() => {});
  }
  saveRecord(){
    this.inProgress = true;
    this.popUp.presentLoading();
    let data = this.adminForm.value;
    data.User_Id = localStorage.getItem("userId");
    if(data.Transaction_type=="Debit"){
      let oldUSer = data.User_Id;
      data.User_Id = data.To_User_Id;
      data.To_User_Id = oldUSer;
    }
    data.Transaction_doneby = localStorage.getItem("userName");
    let formdata = new FormData();
    formdata.append('data',JSON.stringify(data));
    formdata.append('table','wallet');
    this.http.postDataByForm('transaction.php',formdata)
            .then((data: any) => {
              this.popUp.dismisLoading();
              this.inProgress = false;
              this.popUp.presentAlert(data.data.title, data.data.message);
              if(data.status==1){
                this.adminForm.reset(); 
                this.closeModal();
              }
            });
  }
}
