import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { PopupmodelService } from 'src/app/services/popupmodel.service';

@Component({
  selector: 'app-add-biller',
  templateUrl: './add-biller.page.html',
  styleUrls: ['./add-biller.page.scss'],
})
export class AddBillerPage implements OnInit {

  adminForm: FormGroup;
  actionName: any = "save";
  inProgress: boolean = false;
  Users: any = [];

  constructor(
    private modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    private navParams: NavParams,
    private popUp: PopupmodelService,
    private http: HttpService
  ) { 
    this.adminForm = this._formBuilder.group({
      Id: '',
      Service_ID: ['', Validators.required],
      Service_Name: ['', Validators.required],
      Service_Code: ['', Validators.required],
      isAmazon: ['1'],
      isPayzapp: [''],
      allowUsers: ['All']
    });

    if(this.navParams.get('record')){
      this.actionName = "update";
      let record = JSON.parse(this.navParams.get('record'));
      console.log(record);
      this.adminForm.patchValue({
        Id: record.Id,
        Service_ID: record.Service_ID,
        Service_Name: record.Service_Name,
        Service_Code: record.Service_Code,
        isAmazon: record.isAmazon,
        isPayzapp: record.isPayzapp,
        allowUsers: record.allowUsers!=""?record.allowUsers.split(","):""
      });
    }
  }
  getUsers(){
    let formdata = new FormData();
    let data = {"query":"SELECT * FROM users WHERE userrole='User'"};
    formdata.append("table","users");
    formdata.append("data",JSON.stringify(data)); 
    formdata.append("action","select");
    this.http.postDataByForm("common-api.php",formdata)
        .then((data: any) => {
          // console.log(data);
          if(data.status == 1){
              this.Users  = data.data;
          }
        })
        .catch((err) => {
          let title = err.error.error.name;
          let message = err.error.error.message;
          if(err.error.error.name=='UnauthorizedError'){
            title = "Unauthorised Access";
            message = "Authorisation missing, please, login again";
          }
          this.popUp.presentToast(message);
        })
        .finally(() => {});
  }
  closeModal(){
    this.modalCtrl.dismiss();
  }
  ngOnInit() {
    this.getUsers();
  }
  saveRecord(){
    this.inProgress = true;
    this.popUp.presentLoading();
    let data = this.adminForm.value;
    let formdata = new FormData();
    formdata.append('data',JSON.stringify(data));
    formdata.append('action',this.actionName);
    formdata.append('table','billers');
    this.http.postDataByForm('common-api.php',formdata)
            .then((data: any) => {
              this.popUp.dismisLoading();
              this.inProgress = false;
              this.popUp.presentAlert(this.actionName+" successfully", "Action "+this.actionName+" done successfully.");
              if(data.status==1){
                this.adminForm.reset(); 
                this.closeModal();
              }
            });
  }
}
